import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Header from './../layout/Header';
import Footer from './../layout/Footer';

import bgimg from './../../images/background/bg25.jpg';

function sendForm(e) {
    e.preventDefault();

    emailjs.sendForm('service_vzdt5el', 'almol_web_template', e.target, 'user_dQuyzqq7CMDE7S1tZUJG1')
      .then((result) => {
          console.log(result.text);
          document.getElementById('contact-form').innerHTML = "<h3>Thanks!</h3><p>We'll get back to you as soon as we can.</p><br /><br /><br /><br />"
          
      }, (error) => {
          console.log(error.text);
      });
  }

class Contact extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="full-section">
                    <div className="dlab-bnr-inr overlay-primary-light contact-page" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-xs-4">
                                    <div className="row text-white">
                                        <div className="col-lg-12 col-md-6 m-b30" >
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                        Address
													</h5>
                                                    <p>139 Beauchamp Rd, Matraville NSW 2036</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                                        E-mail
													</h5>
                                                    <p className="m-b0"><a href="mailto:sales@almol.com">sales@almol.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6 m-b30 ">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-mobile"></i></span>
                                                        Phone
														</h5>
                                                    <p><a href="tel:61296666755" style={{Color:"#fff"}} >+61 2 9666 6755</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-xs-8">
                                    <form className="inquiry-form dzForm" id="contact-form" onSubmit={sendForm}>
                                        <div className="dzFormMsg"></div>
                                        <h1>Contact Us</h1>
                                        <h3 className="box-title m-t0 m-b10 text-white">We'd Love to Hear From You <span className="bg-primary"></span></h3>
                                        <p>Our customer service team is available during business hours to answer all of your enquiries.</p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="name" type="text" required className="form-control" placeholder="Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="phone_no" type="text" required className="form-control" placeholder="Phone" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="email_address" type="email" className="form-control" required placeholder="Email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="form_message" rows="4" className="form-control" required placeholder="Message"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <button name="submit" type="submit" value="Send" className="site-button button-lg"> <span>Send</span> </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}
export default Contact;